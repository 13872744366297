export default [
  {
    title: 'Catálogos',
    icon: 'FolderIcon',
    children: [
      {
        title: 'Sucursales',
        route: 'catalogos-sucursales',
        icon: 'GridIcon',
      },
      {
        title: 'Empleados',
        route: 'catalogos-empleados',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'Checador',
      },
      {
        title: 'Conceptos de Gastos',
        route: 'catalogos-gastos',
        icon: 'TagIcon',
      },
    ],
  },
];
